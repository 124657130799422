<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <section v-if="isLoad" class="containerGifLoading sizeMinAllView heigth__">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else-if="!isPreLaunch">
    <PxHeader :activeSearchDesktop="false" />
    <publish-button />
    <div class="positionButtonOpportunityFarms absolute z-10">
      <button-opportunity-farms />
    </div>

    <section class="bg-agrogo-green-3 sizeMinAllView">
      <div class="relative flex flex-col bg-agrogo-home">
        <div
          class="flex flex-col space-y-2 md:pb-28 md:px-32 lg:px-72 md:space-y-4 md:pt-36 xl:pt-56"
        >
          <div class="hidden md:flex md:flex-row z-30">
            <search-algolia />
          </div>
          <div class="md:inline hidden absolute positionbuttonBusquedaAvanzada">
            <button
              @click.prevent="advancedSearch"
              class="btn-primary flex items-center justify-center w-32 md:w-56 md:h-8 bg-agrogo-yellow-1 md:rounded-10px z-10"
            >
              <span
                class="font-dinpro font-normal text-agrogo-green-2 text-xs md:text-base"
                >Búsqueda avanzada</span
              >
            </button>
          </div>
        </div>
      </div>
      <!-- CARRUSEL Tipos de fincas -->
      <slider-type-farm />
      <!-- SECCION ENCUENTRA LA FINCA IDEAL PARA TI MOVIL -->
      <div
        class="flex md:hidden items-center bg-agrogo-yellow-2 h-14 justify-around"
      >
        <div class="flex">
          <button
            @click="$router.push('/lista-de-fincas')"
            class="font-nexa font-normal text-base text-left md:text-2xl leading-5"
          >
            Encuentra la Finca
            <p class="font-black leading-3">perfecta para ti</p>
          </button>
        </div>
        <div class="flex">
          <button
            @click.prevent="advancedSearch"
            class="btn-primary flex items-center justify-center w-24 h-9 bg-agrogo-green-2 rounded-xl font-nexa font-normal text-xs text-agrogo-yellow-1 md:w-32 md:text-base"
          >
            Filtrar
            <span class="icon-filter text-3xs pl-2 md:text-xs"></span>
          </button>
        </div>
      </div>
      <!-- SECCION ENCUENTRA LA FINCA IDEAL PARA TI ESCRITORIO-->
      <div
        class="hidden md:flex items-center bg-agrogo-yellow-1 h-16 pl-28"
        v-if="records.length > 0"
      >
        <span class="font-nexa text-agrogo-green-2 font-black text-2xl"
          >Fincas <span class="font-dinpro font-normal">Destacadas</span></span
        >
      </div>
      <!-- CARDSFARMS -->
      <section
        class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 place-content-center place-items-center gap-2 lg:gap-6 px-2 pt-2.5 pb-2 md:px-5 md:py-4"
      >
        <card-farms
          v-for="(item, index) in records"
          :item="item"
          :key="index"
          @updateFarm="updateFarm"
        />
      </section>
    </section>
  </section>
  <modal-video
    v-if="isModal"
    :isModal="isModal"
    :url="url"
    @closeModal="isModal = false"
  />
  <modal-lead
    v-if="isModalLead"
    :isModalLead="isModalLead"
    @closeModalLead="isModalLead = false"
  />
</template>
<script>
import moment from "moment";
import { generateHash } from "@/utils";
import { mapState, mapActions } from "vuex";
import algoliasearch from "algoliasearch/lite";
import metaMixin from "@/mixins/metaMixin";
// mixins
import validationToken from "@/mixins/validationToken";
// components
import PxHeader from "@/components/header/PxHeader";
import ModalVideo from "./components/ModalVideo";
import ModalLead from "./components/ModalLead";
import SliderTypeFarm from "@/components/slider/SliderTypeFarm";
import CardFarms from "@/views/farm/cards/CardFarms";
import PublishButton from "@/components/publishButton/PublishButton";
import SearchAlgolia from "@/components/search/SearchAlgolia";
import buttonOpportunityFarms from "@/components/buttonOpportunityFarms/buttonOpportunityFarms";
moment.locale("es");

export default {
  inject: ["provider"],
  mixins: [validationToken, metaMixin],
  metaInfo() {
    const me = this;
    return me.dataMeta;
  },
  name: "Home",
  components: {
    ModalVideo,
    ModalLead,
    SliderTypeFarm,
    CardFarms,
    PxHeader,
    PublishButton,
    SearchAlgolia,
    buttonOpportunityFarms,
  },
  data() {
    const me = this;
    const algoliaId =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_ALGOLIA_ID_TEST
        : process.env.VUE_APP_ALGOLIA_ID;
    const algoliaPrivateKey =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_PRIVATE_KEY_ALGOLIA_TEST
        : process.env.VUE_APP_PRIVATE_KEY_ALGOLIA;
    return {
      searchClient: algoliasearch(algoliaId, algoliaPrivateKey),
      repository: me.provider.homeRepository,
      favoriteRepository: me.provider.favoriteRepository,
      publishFarmRepository: me.provider.publishFarmRepository,
      selected: "",
      indexName: "alg_properties",
      currentDay: null,
      url: null,
      isModal: false,
      isModalLead: false,
      records: [],
      favorites: [],
      perPage: 2,
      widthResize: null,
    };
  },
  watch: {
    widthResize() {
      const me = this;
      if (me.widthResize >= 1536) {
        me.perPage = 5;
      } else if (me.widthResize >= 1200) {
        me.perPage = 4;
      } else if (me.widthResize >= 768 && me.widthResize <= 1200) {
        me.perPage = 3;
      } else {
        me.perPage = 2;
      }
      me.loadFarms();
    },
  },
  computed: {
    ...mapState("preLaunch", ["isLoad", "isPreLaunch"]),
    ...mapState("user", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("publishFarm", ["restoreData"]),
    async timezone() {
      const me = this;
      try {
        let diff = 0;
        const { data } = await me.repository.timezone();
        me.currentDay = moment(data);
        if (localStorage.getItem("displayDate")) {
          const displayDate = moment(localStorage.getItem("displayDate"));
          diff = me.currentDay.diff(displayDate, "days");
        }
        return diff;
      } catch (error) {
        // console.log(error);
      }
    },
    async loadVideoORimage() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const name = "MODAL_MAIN";
        const {
          data: { data },
        } = await me.repository.find(
          {
            ...dataAditional,
            dev0: process.env.VUE_APP_DEBUG_CUSTOM,
          },
          name
        );
        const urlImg = `${process.env.VUE_APP_AWS_URL}${data.url_media}`;
        me.url = { ...data, urlImg };
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      }
    },
    async loadModal() {
      const me = this;
      const diff = await me.timezone();
      await me.loadVideoORimage();
      const display = localStorage.getItem("display");
      if (!display) {
        localStorage.setItem("displayDate", me.currentDay.format("YYYY-MM-DD"));
        localStorage.setItem("display", "one");
        me.isModal = true;
        return;
      }
      if (display == "one") {
        localStorage.setItem("display", "two");
        me.isModal = true;
      } else {
        if (diff >= 3) {
          localStorage.setItem(
            "displayDate",
            me.currentDay.format("YYYY-MM-DD")
          );
          localStorage.setItem("display", "one");
          me.isModal = true;
        }
      }
    },
    async loadModalLead() {
      const me = this;
      const display = localStorage.getItem("displayLeads");
      if (!display) {
        localStorage.setItem("displayLeads", "one");
        me.isModalLead = true;
      }
    },
    advancedSearch() {
      const me = this;
      me.$router.push({ name: "ListOfFarms", query: { visible: true } });
    },
    screenFarmSimilary() {
      const me = this;
      me.widthResize = screen.width;
    },
    async loadFavorites() {
      const me = this;
      me.favorites = [];
      try {
        const dataAditional = await generateHash();
        const {
          data: { response },
        } = await me.favoriteRepository.getAll({
          ...dataAditional,
        });
        me.favorites = response;
      } catch (error) {
        me.favorites = [];
        // console.log(error);
      }
    },
    loadFarms() {
      const me = this;
      me.records = [];
      const index = me.searchClient.initIndex(me.indexName);
      index
        .search("", {
          hitsPerPage: me.perPage,
        })
        .then(
          ({ hits }) => {
            const items = me.selectRandomItems(hits, me.perPage);
            if (me.favorites.length > 0) {
              items.forEach((el) => {
                const farm = me.favorites.find(
                  (x) => el.property_id == x.property_id
                );
                el.isFavorite = farm ? true : false;
                me.records.push(el);
              });
            } else {
              items.forEach((el) => {
                el.isFavorite = false;
                me.records.push(el);
              });
            }
          },
          () => {
            me.isBusy = false;
          }
        );
    },
    async updateFarm() {
      const me = this;
      me.favorites = [];
      if (me.isAuthenticated) {
        await me.loadFavorites();
      }

      if (me.favorites.length > 0) {
        me.records.forEach((el) => {
          const farm = me.favorites.find(
            (x) => el.property_id == x.property_id
          );
          el.isFavorite = farm ? true : false;
        });
      } else {
        me.records.forEach((el) => {
          el.isFavorite = false;
        });
      }
    },
    // Función para seleccionar elementos aleatorios del array
    selectRandomItems(array, numberElement) {
      const items = [];
      // Copia el array original para evitar modificarlo directamente
      const copyArray = array.slice();

      // Itera hasta que se seleccionen todos los elementos o se agote el array
      while (items.length < numberElement && copyArray.length > 0) {
        // Genera un índice aleatorio dentro del rango del array restante
        const index = Math.floor(Math.random() * copyArray.length);
        // Añade el elemento seleccionado al array de elementos aleatorios
        items.push(copyArray[index]);
        // Elimina el elemento seleccionado del array copiado
        copyArray.splice(index, 1);
      }

      return items;
    },
    async getHome() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.publishFarmRepository.getAllNames("Home", dataAditional);
        if (data?.metadata) {
          me.meta = data?.metadata;
          me.loadDataMeta();
        }
      } catch (error) {
        // console.log(error);
      }
    },
  },
  created() {
    const me = this;
    window.addEventListener("resize", function () {
      me.screenFarmSimilary();
    });
  },
  async mounted() {
    const me = this;
    await generateHash();
    if (!me.isPreLaunch) {
      if (me.isAuthenticated) {
        me.loadModal();
        me.loadFavorites();
      } else {
        me.loadModalLead();
      }
      me.screenFarmSimilary();
      me.restoreData();
    }
    me.getHome();
  },
};
</script>
<style lang="css">
.heigth__ {
  height: 100vh !important;
}
.ais-Hits {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  place-content: center;
  place-items: center;
  gap: 0.5rem;
}
@media (min-width: 768px) {
  .ais-Hits {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }
}
@media (min-width: 1280px) {
  .ais-Hits {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
  }
}
.ais-InstantSearch {
  width: 100%;
  z-index: 5 !important;
}
.search-bar-border {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 0rem;
}
.icon-search-bar-boder {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 0rem;
}
/* .ais-SearchBox-input {
  background: red;
} */
</style>
